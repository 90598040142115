import type { O } from "ts-toolbelt";

import loadDictionary from "@portal/i18n/loadDictionary";
import type {
  ShowData as Settings,
  ShowDataMatomo,
  ShowDataServiceZone,
} from "@portal/types/Settings/Show";
import type { MyUser } from "@portal/types/Shared/MyUser";
import type { IMapper, Map } from "@portal/types-global";
import type { IAppConfig, IMatomo } from "@portal/types-shared/IAppConfig";
import {
  removeUtmTagsFromSearchParams,
  setUtmTagsToStorage,
} from "@portal/unauthorized/helpers/utmTags";
import type { ITheme } from "@portal/unauthorized/zones/theme";

import "regenerator-runtime/runtime";
import "intl-pluralrules";
import "_/../../css/custom/loader.css";

import intlPolyfill from "_/i18n/intl-polyfill";

const NOTIFICATIONS_PATH = "/notifications/";

type Language = MyUser["locale"];

interface IToFunction extends IMapper {
  Return: () => Promise<this["Value"]> | this["Value"];
}

function getLanguage(userLanguage: Language): Language {
  const { searchParams } = new URL(
    window.location.href,
    window.location.origin,
  );
  const langFromUrl = searchParams.get("lang") as Language | null;

  return langFromUrl ?? userLanguage;
}

function getMatomo(matomo?: ShowDataMatomo | null): IMatomo | null {
  if (matomo) {
    return {
      siteId: matomo.site_id,
      urlBase: matomo.url_base,
      srcUrl: matomo.src_url,
      trackerUrl: matomo.tracker_url,
      container: matomo.container,
    };
  }
  return null;
}

function setDocumentTitle() {
  const titles = {
    en: "Servers.com – Customer Portal",
    ru: "Servers.ru – Портал Пользователя",
  };
  document.title = titles[window.appConfig.language];
}

function getConfig(config: Settings): IAppConfig {
  return {
    dictionary: {},
    isAuthorized: config.user !== null,
    isDev: !import.meta.env.PROD,
    isPrivate: config.private,
    language: getLanguage(config.user?.locale ?? config.service_zone.locale),
    loadBalancerChartUnit: config.load_balancer_chart_unit,
    matomo: getMatomo(config.matomo),
    safariWebService: {
      url: config.safari_web_service_url,
      domain: config.safari_web_service_domain,
    },
    serviceZone: config.service_zone,
    stripePublishableKey: null,
    webpushVapidPublicKey: config.webpush_vapid_public_key,
    howDidYouHearAboutUs: config.how_did_you_hear_about_us,
  };
}

async function initModule<
  TInit extends (appConfig: IAppConfig, ...moduleArgs: any[]) => void,
>(
  loader: () => Promise<{ default: TInit }>,
  ...args: Map<IToFunction, Parameters<TInit>>
) {
  const [appConfig, ...restArgs] = (args as any).map((arg: () => unknown) =>
    arg(),
  ); // FIXME: waited for TS 4.6
  const [dictionary, module, ...resolvedArgs] = await Promise.all([
    loadDictionary(appConfig.language), // preload dictionary
    loader(),
    ...restArgs,
  ]);

  const polyfillArgs = [
    {
      ...appConfig,
      dictionary: dictionary.default,
    },
    ...resolvedArgs,
  ] as Parameters<TInit>;

  intlPolyfill(module.default, ...polyfillArgs);
}

const app = () => import("_/app");
const unauthorizedResolver = () => import("@portal/unauthorized");
const serversCom = () => import("@portal/unauthorized/zones/servers.com");
const serversRu = () => import("@portal/unauthorized/zones/servers.ru");

async function authorized(config: O.NonNullable<Settings, "user">) {
  const appConfig = getConfig(config);

  window.sentry = config.sentry;
  window.release = config.release;
  window.appConfig = {
    ...appConfig,
    stripePublishableKey: config.stripe_publishable_key,
  };

  setDocumentTitle();

  await initModule(
    app,
    () => appConfig,
    () => config.user,
  );
}

const themes: Record<
  ShowDataServiceZone["portal_theme"],
  () => Promise<ITheme>
> = {
  "servers.com": serversCom,
  "servers.ru": serversRu,
};

async function unauthorized(config: Settings) {
  const appConfig = getConfig(config);
  window.appConfig = appConfig;

  setDocumentTitle();

  const { pathname, searchParams } = new URL(window.location.href);
  const redirectUrl = searchParams.get("redirect_url") ?? "";

  setUtmTagsToStorage(searchParams);

  if (pathname.indexOf(NOTIFICATIONS_PATH) === 0) {
    window.sessionStorage.setItem(
      "redirectUrl",
      `/${pathname.substring(NOTIFICATIONS_PATH.length)}`,
    );
    window.location.replace("/");
  } else if (redirectUrl.length > 0) {
    const clearRedirectUrl = removeUtmTagsFromSearchParams(redirectUrl);
    window.sessionStorage.setItem("redirectUrl", clearRedirectUrl);
    window.location.replace(pathname);
  } else {
    await initModule(
      unauthorizedResolver,
      () => appConfig,
      themes[appConfig.serviceZone.portal_theme],
    );
  }
}

async function deauthorize() {
  await fetch("/auth/logout", { method: "DELETE" });
  window.location.reload();
}

function isPasswordChangeLink() {
  return window.location.pathname.indexOf("/password/change") === 0;
}

const isAuthorized = (
  settings: Settings,
): settings is O.NonNullable<Settings, "user"> => settings.user !== null;

fetch("/settings", {
  credentials: "include",
  method: "GET",
  headers: {
    accept: "application/json",
  },
})
  .then(async (response) => {
    if (!response.ok) {
      throw new Error("Unknown error");
    }

    const { data: config }: { data: Settings } = await response.json();

    if (isPasswordChangeLink() && isAuthorized(config)) {
      await deauthorize();
    } else if (isAuthorized(config)) {
      await authorized(config);
    } else {
      await unauthorized(config);
    }
  })
  // eslint-disable-next-line no-console
  .catch((err) => console.error("Failed to fetch /settings", err));
